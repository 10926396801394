import React from "react";
import { PortableText } from "@portabletext/react";
import ContentLink from "./ContentLink";
import DefinitionLink from "./DefinitionLink";
import TextContentTable from "../TextContentTable";

export const HEADING_URL_REGEX = /[^a-z0-9]+/g;

const isPrevChildAReference = (block, childIndex) => {
  if (childIndex === 0 || !block.children) {
    return false;
  }

  const prevChild = block.children[childIndex - 1];

  if (!prevChild || prevChild.marks.length === 0) {
    return false;
  }

  return prevChild.marks.some((mark) => {
    const markDef = block.markDefs.find((def) => def._key === mark);
    if(markDef === undefined) {
      return false
    }
    return markDef._type === "formattedReferenceLink";
  });
};

const TextContentRenderer = ({value, referenceIds = []}) => {
  // Sets the separator prop on all formattedReferenceLink marks
  value.forEach((block) => {
    if(block._type !== 'block') {
      return;
    }

    block.children.forEach((child, childIndex) => {
      child.marks.forEach((mark) => {
        const markDef = block.markDefs.find((def) => def._key == mark);
        if (
          markDef !== undefined &&
          markDef._type === "formattedReferenceLink"
        ) {
          markDef.formattedReference.separator = isPrevChildAReference(
            block,
            childIndex
          );
        }
      });
    });
  });

  const parameterize = (content) => {
    if (content[0] !== undefined) {
      return content[0].toLowerCase().replace(HEADING_URL_REGEX, "-");
    }
  };

  const components = {
    block: {
      h1: ({ children }) => (
        <h3 className="article-subheading" id={parameterize(children)}>
          {children}
        </h3>
      ),
      h2: ({ children }) => <h4 id={parameterize(children)}>{children}</h4>,
      h3: ({ children }) => <h5 id={parameterize(children)}>{children}</h5>,
      h4: ({ children }) => <h6 id={parameterize(children)}>{children}</h6>,
      normal: ({ children }) => (
        <>
          <p>{children}</p>
          <br />
        </>
      ),
    },
    types: {
      table: (object) => <TextContentTable rows={object.value.rows} />,
    },
    marks: {
      sub: ({ children }) => <sub>{children}</sub>,
      sup: ({ children }) => <sup>{children}</sup>,
      contentLink: ({ value, children }) => (
        <ContentLink contentId={value.content._ref} heading={value.heading}>
          {children}
        </ContentLink>
      ),
      definitionLink: ({ value, children }) => (
        <DefinitionLink definitionId={value.definition._ref}>
          {children}
        </DefinitionLink>
      ),
      formattedReferenceLink: ({ value, children }) => {
        const { _ref, separator } = value.formattedReference;
        const content = referenceIds.indexOf(_ref) + 1;
        return <sup>{separator ? `, ${content}` : content}</sup>;
      },
    },
  };

  return <PortableText value={value} components={components} />;
};

export default TextContentRenderer;
