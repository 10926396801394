import React from "react";
import GlossaryNavigationButton from "./GlossaryNavigationButton";

const GlossaryNavigationList = (props) => {
  const { glossaryKeys, url } = props;

  const navigationButtons = glossaryKeys.map(({ key, anchor }) => {
    return <GlossaryNavigationButton key={`${key}-navigation`} item={key} url={url} anchor={anchor} />;
  });

  return <div className="glossary__navigation_list">{navigationButtons}</div>;
};

export default GlossaryNavigationList;
