import React from "react";
import { PortableText } from "@portabletext/react";
import arrowIcon from "../../../assets/images/ic_arrow-right.svg";

const GlossaryItem = ({glossary, url}) => {
  const {
    title,
    description,
    fullDescription,
    relatedTerms,
    source,
    imageUrl,
    anchor,
    slug,
  } = glossary;

  return (
    <div className="glossary__item paragraph__width">
      <a className="glossary__anchor" id={anchor}></a>
      <h3 className="glossary__title">{title}</h3>

      {imageUrl && (
        <div className="right mb3">
          <img src={imageUrl} alt={title} />
        </div>
      )}

      <div className="glossary__description mb3">
        <PortableText value={description} />
      </div>

      <div className="glossary__info">
        {relatedTerms && (
          <span className="glossary__detail">
            <div className="glossary__header">Related Terms</div>
            <div className="glossary__description">{relatedTerms}</div>
          </span>
        )}
        {source && (
          <span className="glossary__detail">
            <div className="glossary__header">Source</div>
            <div className="glossary__description">{source}</div>
          </span>
        )}
      </div>
      {fullDescription && (
        <span className="glossary__learn-more">
          <a className="button text__button" href={`${url}/${slug}`}>
            Learn More
            <img src={arrowIcon} alt="" className="text__button--arrow" />
          </a>
        </span>
      )}
    </div>
  );
};

export default GlossaryItem;
