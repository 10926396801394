import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import ScrollToHeadingDropDown from './ScrollToHeadingDropDown'

const ArticleBreadcrumbs = (props) => {
  return (
    <div className='breadcrumbs__container'>
      <Breadcrumbs { ...props }>
        <ScrollToHeadingDropDown
          headingQuery='.article-subheading'
        />
      </Breadcrumbs>
    </div>
  )
}

export default ArticleBreadcrumbs
