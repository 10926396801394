import React from 'react'

const GlossaryNavigationButton = ({ item, url, anchor }) => {
  return(
    <a className={"glossary__navigation_button"} href={`${url}/#${anchor}`} >
      { item }
    </a>
  )
}

export default GlossaryNavigationButton