import React, { useMemo } from "react";
import GlossaryNavigationList from "./GlossaryNavigationList";
import GlossaryItem from "./GlossaryItem";

const Glossary = (props) => {
  const { groupedGlossaries, url } = props;

  const glossaryKeys = useMemo(() => {
    return Object.keys(groupedGlossaries).map((key) => {
      return {
        key,
        anchor: `letter-${key}`,
      };
    });
  }, [groupedGlossaries]);

  return (
    <div className="glossary">
      <GlossaryNavigationList glossaryKeys={glossaryKeys} url={url} />
      <hr className="horizontal_rule" />
      <div className="glossary__list">
        {glossaryKeys.map(({ key, anchor }) => {
          return (
            <>
              <h2 className="glossary__label" key={`${key}-label`} id={anchor}>
                {key}
              </h2>
              {groupedGlossaries[key].map((glossary) => (
                <GlossaryItem key={glossary.title} url={url} glossary={glossary} />
              ))}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Glossary;
